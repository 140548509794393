<template>
	<div class="layout">
		<v2-app-header />

		<verification-banner />
		<div class="container">
			<breadcrumbs />
		</div>

		<transition
			name="fade"
		>
			<loading
				v-show="getLoadingIsVisible"
			/>
		</transition>
		<transition name="fade" mode="out-in">
			<component
				:is="getRouteComponent"
				:key="$route.path"
			/>
		</transition>

		<v2-top-picks v-if="showTopPicks && false" />

		<v2-app-footer />

		<notification />
		<modal />
		<screen-recorder-controls />
	</div>
</template>

<script>

	import V2AppHeader from '@/components/v2/header/V2AppHeader';
	import V2TopPicks from '@/components/v2/topPicks/V2TopPicks';
	import V2AppFooter from '@/components/v2/footer/V2AppFooter';
	import VerificationBanner from '@/components/profile/VerificationBanner';
	import Loading from '@/components/ui/Loading';
	import Notification from '@/components/ui/Notification';
	import FourOhFour from '@/pages/404';
	import Modal from '@/components/ui/modal/Modal';
	import ScreenRecorderControls from '@/components/screenRecorder/ScreenRecorderControls';
	import Breadcrumbs from '@/components/ui/Breadcrumbs';

	export default {
		components: {
			V2AppHeader,
			V2TopPicks,
			VerificationBanner,
			Loading,
			FourOhFour,
			V2AppFooter,
			Notification,
			Modal,
			ScreenRecorderControls,
			Breadcrumbs
		},
		computed: {
			showTopPicks () {
				return this.$route.meta?.showTopPicks;
			},

			getLoadingIsVisible () {
				return this.$store.getters['ui/getLoadingIsVisible'];
			},
			getRouteComponent () {
				if (this.$store.getters['ui/getErrorIsVisible']) {
					return 'FourOhFour';
				}
				return 'router-view';
			}
		}
	};

</script>

<style lang="scss" scoped>

</style>
